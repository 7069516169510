@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  min-height: 100vh;
}

body {
  height: 100%;
  background-color: white;
}

.fill-black {
  fill: black;
}
